<template>
    <div v-if="showDidYouMean" class="alert-did-you-mean">
        <div class="box-item">
            <div class="text-content-header">{{$t('did_you_mean.alert')}}</div>
            <div class="text-strong">{{$t('did_you_mean.title')}} :</div>
            <div class="text-result">
              <span class="title_name">{{$t('did_you_mean.name')}} :</span>
              <div class="item" v-for="(item, key) in didYouMean"  :key="key"  @click="addToBoxName(item)">{{item}}</div>
            </div>
            <div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "DidYouMean",

  props: {
   
  },

  data() {
    return {
      name : []
    };
  },

  methods: {
    addToBoxName(str){
      this.name = []
      this.name.concat(this.toBoxName)
      this.name.push(str);
      this.$store.dispatch("did_you_mean/toBoxName",  this.name )
      this.$store.dispatch("did_you_mean/setLoadDidYouMean",  false )
      this.$store.dispatch("did_you_mean/resetDidYouMean" )
    }
  },
  updated() {
    console.log(this.name)
    console.log(this.didYouMean)
  },
  computed: {     
      showDidYouMean(){ 
          return this.$store.getters['did_you_mean/load_did_you_mean']
      },  
      didYouMean(){ 
          return this.$store.getters['did_you_mean/did_you_mean']
      },
      toBoxName(){ 
          return this.$store.getters['did_you_mean/to_box_name']
      }
  },
};
</script>

<style scoped>
.text-result div.title_name{
    color: #7d8693;
    font-size: 13px;
    display: inline-block;
    padding-right: 5px;
    font-weight: lighter;
    margin: 0;
}
.text-result div.item{
  display: inline-block;
  color: red;
  font-weight: bold;
  font-size: 11px;
  padding-right: 5px;
  cursor: pointer;
}
</style>
