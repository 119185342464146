<template>
    <div >
        <Header />  
        <AlertBox v-if="error != '' " :message="error" variant="danger"/>
        <div class="container container-global container-inter search_page">
            <form @submit.prevent="handleSubmit">    
            <div class="wrap-form-content">
                <!-- <div class="form-inter"> -->
                    <!-- <div class="alert alert-form" style="">Le champ ne peut pas être vide.</div> -->
                    <DidYouMean/>
                    <div class="mb-3 row">
                        <div class="col-12 col-md-6">
                            <div class="form-input">
                                <p class="title-top-input">{{datas.name.title}}</p> 
                                <Multiselect
                                    v-model="datas.name.data"
                                    mode="tags"
                                    :search="true"
                                    :searchable="true"
                                    :placeholder="datas.name.placeholder"
                                    @open="filteredName"
                                    :options="datas.name.items"
                                    track-by="value"
                                    class="custom-tags-input no-caret"
                                    :limit="limit"
                                    :loading="loadingName"
                                    :disabled="loadingName"
                                    :showOptions="datas.name.showOptions"
                                    @search-change="optionsDisplay"
                                    @close="onCloseDrugname"
                                    :strict="false"
                                    :closeOnSelect="false"
                                    @select="onCloseDrugname"
                                />
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-rows">
                                <div class="form-input">
                                    <p class="title-top-input">{{datas.date_start.title}}</p>
                                    <datepicker 
                                        v-model="datas.date_start.data" 
                                        :locale="language"
                                        inputFormat="MM/yyyy" 
                                        minimumView="month"
                                        class="form-control form-cs bg-white" 
                                        :placeholder="$t('MM/YYYY')" 
                                        :clearable="true"
                                        >  
                                        <template v-slot:clear>
                                            <div class="date_clearable" @click="datas.date_start.data = null"><em>x</em></div>
                                        </template>
                                    </datepicker>                                    
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-rows">
                                <div class="form-input">
                                    <p class="title-top-input font14">{{datas.date_end.title}}</p>
                                    <datepicker 
                                        v-model="datas.date_end.data" 
                                        :locale="language"
                                        inputFormat="MM/yyyy" 
                                        minimumView="month"
                                        class="form-control form-cs bg-white" 
                                        :placeholder="$t('MM/YYYY')" 
                                        :clearable="true"
                                        >  
                                        <template v-slot:clear>
                                            <div class="date_clearable" @click="datas.date_end.data = null"><em>x</em></div>
                                        </template>
                                    </datepicker> 
                                </div>
                            </div>
                        </div>
                    <div class="col-12 col-md-6 pr-1">
                        <div class="form-input">
                            <p class="title-top-input">{{datas.therapeutic.title}}</p>
                            <Multiselect
                                v-model="datas.therapeutic.data"
                                v-bind="datas.therapeutic"
                                mode="tags"
                                :search="true"
                                :searchable="true"
                                :placeholder="datas.therapeutic.placeholder"
                                @open="filteredTherapeutic"
                                :options="datas.therapeutic.items"
                                track-by="value"
                                class="custom-tags-input"
                                :limit="limit"
                                :caret="false"
                                ref="multiselectTherapeutic"
                                :loading="loadingTherapeutic"
                                :disabled="loadingTherapeutic"
                                @select="selectTherapeuticArea"
                                @deselect="unselectTherapeuticArea"
                                @clear="unselectAlltherapeuticArea"
                                :strict="false"
                                :closeOnSelect="false"
                            />
                            <span class="input-search-plus" data-bs-toggle="modal" data-bs-target="#modal-therapeutic" v-if="!loadingTherapeutic"></span> 
                        </div>
                    </div>  
                    
                    <div class="col">
                        <div class="form-checkbox">
                             <div v-for="(item, key) in datas.disease.items" :key="key" class="box-check box-check-bggray p-2 form-input">
                                <p class="title-top-input">{{datas.disease.title}}</p>
                                <label>
                                    <input type="checkbox" :value="item.value" v-model="datas.disease.data"  name="disease">
                                    <span class="check-recherche"></span>
                                    <div class="txt-check bold"> {{item.display}} </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col"> 
                        <div v-for="(item, key) in datas.mk_orphelin.items" :key="key"  class="box-check box-check-bggray p-2 form-input">
                            <p class="title-top-input">{{datas.mk_orphelin.title}}</p>
                            <label>
                                <input type="checkbox" :value="item.value" v-model="datas.mk_orphelin.data"  name="mk_orphelin">
                                <span class="check-recherche"></span>
                                <div class="txt-check bold"> {{item.display}} </div>
                            </label>
                        </div>
                    </div>
                    <div class="col">
                        <div v-for="(item, key) in datas.en_cours.items" :key="key"  class="box-check box-check-bggray p-2 form-input">
                            <p class="title-top-input">Status</p>
                            <label>
                                <input type="checkbox" :value="item.value" v-model="datas.en_cours.data"  name="en_cours">
                                <span class="check-recherche"></span>
                                <div class="txt-check bold"> {{item.display}} </div>
                            </label>
                        </div> 
                    </div>

                    <div class="px-0">
                        <div class="box_country box_country_inter d-flex justify-content-between">
                            <div v-for="(item,key) in regulatoryAgencies" :key="key" class="box-item-white box-border-gray col-lg-2 col-md-3 col-12">
                                <div class="box-list-countries">
                                    <p class="item-header item-country d-flex justify-content-between">
                                        <span class="font14Bold">REGULATORY</span>
                                        <a class="btn-border-gray btn-gray selectAll-btn"  @click="activeAllAgency(key,item)">
                                            <template v-if="datas.agency.groupsState.includes(key)">
                                                Unselect All
                                            </template>
                                            <template v-else>
                                                Select All
                                            </template>
                                        </a>
                                    </p>
                                </div>
                                <div v-for="(item_data, key_data) in item" :key="key_data" class="box-list-countries">
                                    <a :class="datas.agency.data.includes(key_data) ? 'item-country active' : 'item-country'"  @click="activeAgency(key_data)">
                                        <img :alt="key_data" v-if="item_data.icon_flags" :src="require('@/assets/images/flag/new/'+item_data.icon_flags+'.svg')" width="20" height="20" />
                                        <span class="font12" >{{key_data}}</span>
                                    </a>
                                    <span :class="defaultCountry && defaultCountry.agency_id && defaultCountry.agency_id.includes(item_data.agency_id) ? 'favorite-item check-star active' : 'favorite-item check-star'"  @click="activeUserAgency('agency_id', item_data.agency_id)"></span>
                                </div>
                            </div>
                            <div class="col-lg-10 col-md-9 col-12 container-hta" style="padding-left: 15px;">
                                <div class="box-item-white box-border-gray">
                                    <a class="item-header item-country item-header-title" style="cursor: unset;">
                                        <span class="font14Bold">HTA & REIMBURSEMENT</span>
                                    </a>
                                    <div class="d-flex justify-content-center super-container-box-list-countries">
                                        <div v-for="(item,key) in CountryGroup" :key="key" class="container-box-list-countries" v-bind:style="{display: Object.keys(item).length > 0 ? 'block' : 'none'}">
                                            <div class="box-list-countries">
                                                <p class="item-header item-country d-flex justify-content-between">
                                                    <span class="font14Bold">{{key}}</span>
                                                    <a class="btn-border-gray btn-gray selectAll-btn" v-bind:class="{active: datas.agency.groupsState.includes(key)}" @click="activeAllAgency(key)">
                                                        <template v-if="datas.agency.groupsState.includes(key)">
                                                            Unselect All
                                                        </template>
                                                        <template v-else>
                                                            Select All
                                                        </template>
                                                    </a>
                                                </p>
                                            </div>
                                            <div v-for="(item_data, key_data) in item" :key="key_data" class="box-list-countries">
                                                <a :class="datas.agency.data.includes(key_data) ? 'item-country active' : 'item-country'"  @click="activeAgency(key_data)"> 
                                                    <img :alt="key_data" :src="require('@/assets/images/flag/new/'+item_data.icon_flags+'.svg')" width="20" height="20" />
                                                    <!-- show information only for Columbia -->
                                                    <span class="font12" v-if="key_data=='Colombia'" data-bs-toggle="tooltip" trigger="hover focus click" title="The agency website for Colombia is no longer operational. Prismaccess retains all historical data and continues monitoring for new data. Please contact us if you have any questions." style="margin-left: 0px;">{{key_data}}<i class="ms-2 fas fa-info"></i></span>
                                                    <span class="font12" v-else style="margin-left: 0px;">{{key_data}}</span>
                                                </a>
                                                <span :class="defaultCountry && defaultCountry.country_id && defaultCountry.country_id.includes(item_data.country_id) ? 'favorite-item check-star active' : 'favorite-item check-star'"  @click="activeUserAgency(type='country_id', item_data.country_id)"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <div class="box-border-gray box-item-white p-3">
                    <a class="text-title" @click="toggleBoxEssais = !toggleBoxEssais" role="button">
                        Clinical studies <span class="bt-icon-down"><em :class="'fa fa-chevron-' + (toggleBoxEssais ? 'up' : 'down') "></em> </span>
                    </a>
                    <div class="collapse" id="collapseBox" v-bind:class="{'show': toggleBoxEssais}">
                        <div class="wrapper-box-form">
                            <div class="row">
                                <div class="form-input form-input-text-normal col-12 col-sm-6">
                                    <p class="text-top-input">NCT Numbers</p>
                                    <Multiselect
                                        v-model="datas.nct_numbers.data"
                                        :options="datas.nct_numbers.items"
                                        mode="tags"
                                        :searchable="true"
                                        :createTag="true"
                                        no-options-text=""
                                        no-results-text=""
                                        :placeholder="datas.nct_numbers.placeholder"
                                        track-by="value"
                                        class="custom-tags-input nct_input"
                                        @tag="nctValidation"
                                        ref="multiselectNct"
                                        :showOptions="false"
                                        :caret="false"
                                        @search-change="autoAddTag"
                                        :closeOnSelect="false"
                                    />
                                </div>
                                <div class="form-input form-input-text-normal col-12 col-sm-6">
                                    <p class="text-top-input">Endpoint type</p>
                                    <Multiselect
                                        v-model="datas.measure_type.data"
                                        mode="tags"
                                        :placeholder="datas.measure_type.placeholder"
                                        :options="datas.measure_type.items"
                                        :closeOnSelect="false"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-input form-input-text-normal col-12 col-sm-6">
                                    <p class="text-top-input">{{datas.phase.title}}</p>
                                    <Multiselect
                                        v-model="datas.phase.data"
                                        mode="tags"
                                        :placeholder="datas.phase.placeholder"
                                        :options="datas.phase.items"
                                        :closeOnSelect="false"
                                    />
                                </div>
                                <div class="form-input form-input-text-normal col-12 col-sm-6">
                                    <p class="text-top-input">Endpoint search by keywords</p>
                                        <Multiselect
                                            v-model="datas.measure_title.data"
                                            mode="tags"
                                            :search="true"
                                            :searchable="true"
                                            :placeholder="datas.measure_title.placeholder"
                                            @open="filteredMeasureTitle"
                                            :options="datas.measure_title.items"
                                            track-by="value"
                                            class="custom-tags-input"
                                            :limit="limit"
                                            :strict="false"
                                            :closeOnSelect="false"
                                        />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-input form-input-text-normal col-12 col-sm-6">
                                    <p class="text-top-input">{{ datas.arm.title }}</p>
                                    <Multiselect
                                        v-model="datas.arm.value"
                                        :options="datas.arm.options"
                                        :close-on-select="true"
                                        :closeOnSelect="false"
                                    />
                                </div>
                                <div class="form-input form-input-text-normal col-12 col-sm-6">
                                    <p class="text-top-input">Endpoint search by full title</p>
                                    <Multiselect
                                        v-model="datas.endpoint_search.data"
                                        mode="tags"
                                        :searchable="true"
                                        :createTag="true"
                                        no-options-text=""
                                        no-results-text=""
                                        :placeholder="datas.endpoint_search.placeholder"
                                        track-by="value"
                                        class="custom-tags-input no-caret"
                                        :showOptions="false"
                                        :caret="false"
                                        :closeOnSelect="false"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="box-save-search" data-bs-toggle="modal" data-bs-target="#modalMemory" @click="modalMemory">
                    {{$t('Save my search')}}
                </div>
                <div class="button-search">
                    <button type="submit" class="btn btn-orange" style="width: 60%;" >{{$t('Launch the search')}}</button>
                </div>
            </div>
            </div>
                
            <span class="bg-space" style="left: 0;"></span>
            <ModalSelection :title="datas.therapeutic.title" :list="autocomplete_therapeutic" :checkedTherapeuticArea="checkedTherapeuticArea" name="therapeutic" @onModal="onModal"/>
            <ModalMemory :form_data="form_data" :form_type="form_type" :form_url="form_url" />
            </form>
        </div>   
        <Footer />
        <!-- Loading -->
        <div class="loading_parent" v-if="isLoad" style="display:block">
            <div class="la-line-scale la-dark la-2x main-loading"><div></div><div></div><div></div><div></div><div></div></div>
            <div class="load-overlay"></div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Layout/Header.vue' 
import Footer from '@/components/Layout/Footer.vue';
import Multiselect from '@vueform/multiselect'
import AlertBox from "@/components/Layout/AlertBox.vue";
import ModalSelection from '@/components/elements/modal_selection.vue'
import ModalMemory from '@/components/elements/modal_memory.vue'
import DidYouMean from '@/components/elements/did_you_mean.vue'
import Datepicker from 'vue3-datepicker'
import '@vueform/multiselect/themes/default.css'
import '@/assets/css/multiselect-tagsinput.css'
import moment from 'moment'
import { isAdmin, base64url_encode} from '@/utils'; 
import { enGB, fr, de } from 'date-fns/locale'

export default {
  name: 'inter',
  components: {
      Header, 
      Footer,
      Multiselect,
      AlertBox,
      ModalSelection,
      Datepicker,
      ModalMemory,
      DidYouMean,
  },
  data:  function(){
        return {     
            languages : {enGB, fr, de },     
            isLoad : true,
            datas : {
                name : {
                    title : this.$t('simple.name'),
                    style : "inline",
                    clear : true,
                    placeholder : "ex: Opdivo",
                    data : [],
                    items: [],
                    input : "",
                    showOptions: false
                },
                therapeutic : {
                    title : this.$t('simple.therapeutic'),
                    style : "inline",
                    clear : true,
                    placeholder : "ex: Melanoma",
                    data : [],
                    items: [],
                    input: ""
                },
                mk_orphelin : {
                    title : this.$t('simple.Orphan designation'), 
                    style : 'inline',  
                    items : [
                        {value : "1", display : this.$t('Orphan drug') }, 
                    ],                
                    data : [],
                },
                en_cours : {
                    title : "", 
                    style : 'inline',  
                    items : [
                        {value : "1", display : this.$t('simple.In development') }, 
                    ],                
                    data : [],
                },
                date_start : {
                    title : this.$t('date_start'), 
                    style : 'inline', 
                    data : null,
                },
                date_end : {
                    title : this.$t('date_end'), 
                    style : 'inline', 
                    data : null,
                },
                agency : {
                    title : "", 
                    style : 'inline', 
                    data : [],
                    groupsState: [],
                },
                phase : {  
                    title : this.$t('essais_clinique.phase'),                  
                    placeholder : this.$t("Choose an item"),
                    items : [
                        {value : "Phase 1", label : "Phase 1"},
                        {value : "Phase 1/Phase 2", label : "Phase 1/Phase 2"},
                        {value : "Phase 2", label : "Phase 2"},
                        {value : "Phase 2/Phase 3", label : "Phase 2/Phase 3"},
                        {value : "Phase 3", label : "Phase 3"},
                        {value : "Phase 4", label : "Phase 4"},
                        {value : "N/A", label : "N/A"}],
                    data : [],
                    main: "EssaisClinique"
                },
                measure_type : {  
                    title : this.$t('essais_clinique.phase'),                  
                    placeholder : this.$t("Choose an item"),
                    items : [
                        {value : "other", label : "Other"},
                        {value : "primary", label : "Primary"},
                        {value : "secondary", label : "Secondary"}],
                    data : [],
                    main: "EssaisClinique"
                },
                nct_numbers: {
                    data: [],
                    items: [],
                    placeholder: 'NCT87578678',
                    style : "inline",
                    clear : true,
                    input : "",
                    main: "EssaisClinique",
                    validation: /^(NCT)[0-9]{8}$/
                },
                measure_title : {
                    title : this.$t('essais_clinique.primary_endpoint'),
                    placeholder : this.$t('Write here')+'...',
                    data : [],
                    items: [],
                    input : "",
                    main : 'EssaisClinique'
                },
                endpoint_search : {
                    data: [],
                    items: [],
                    placeholder: this.$t('Write here')+'...',
                    style : "inline",
                    clear : true,
                    input : "",
                    main: "EssaisClinique",
                },
                arm: {
                    title : this.$t('Single arm trial'),  
                    value: null,
                    options: ['No', 'Yes'],
                    main: "EssaisClinique",
                    placeholder: this.$t("Choose an item")
                },
                disease: {
                    title: this.$t('Disease'),
                    style : 'inline',  
                    items : [
                        {value : "1", display : this.$t('Rare disease') }, 
                    ],                
                    data : [],
                }
                
            }, 
            modal : {
                therapeutic : {open : false, title : this.$t("simple.therapeutic"), result:[]}
            },
            lang : localStorage.getItem('language'),
            form_data : "",
            form_url : "",
            form_type : "",
            toggleBoxEssais: false,
            error: "",
            limit: 20,
            loadingName: true,
            loadingTherapeutic: true,
            checkedTherapeuticArea: []
        }    
    },    
    updated(){
        this.updateDefaultAgency()
    },
    mounted(){ 
        this.loadDefaultAgency() 
        this.loadAccessAgency() 
        this.loadAutocomplete() 
        this.$store.dispatch("agency/getgroupAgency")
    }, 
    methods: {
        /**
         * Active agency by default with favorite agency [star]
        */
        updateDefaultAgency(){
            if(this.isLoad && this.datas.agency.data.length === 0 && Object.keys(this.groupAgency).length > 0){
                for (let key in this.groupAgency) {
                    for (let country in this.groupAgency[key]) {
                        if((key==='Regulatory' && this.defaultCountry.agency_id.includes(this.groupAgency[key][country].agency_id)) || 
                            (key!=='Regulatory' && this.defaultCountry.country_id.includes(this.groupAgency[key][country].country_id))){
                            if(!this.datas.agency.data.includes(country)){
                                this.datas.agency.data.push(country)
                            }
                        }
                    }
                }
                if(Object.keys(this.groupAgency).length > 0){
                    this.isLoad = false
                }
            }
        },
        /**
         * Triggers on "save my search" button click
        */
        modalMemory() { 
            let data = this.formDataWithOutAgency()
            if(data.length != 0){
                this.form_data = base64url_encode(this.formData()) // eslint-disable-line
                this.form_url = this.handleSubmit(false)
                this.form_type = "inter"
            }else{
                this.form_data = ""
                this.form_url = ""
            }
        },

        /**
         * Set agency active for current user
         * @property {string} type
         * @property {number} id
        */
        activeUserAgency(type, id){
            this.$store.dispatch("inter/setDefaultAgency",{ type : type, id : id})
        },
        /**
         * Select all agencies for a specific group
        */
        activeAllAgency(key){
            let index_group = this.datas.agency.groupsState.indexOf(key);
            if (index_group > -1) {
                this.datas.agency.groupsState.splice(index_group, 1);
            } else {
                this.datas.agency.groupsState.push(key);
            }
            
            index_group = this.datas.agency.groupsState.indexOf(key);
            for (let item_index in this.groupAgency[key]) {
                let index = this.datas.agency.data.indexOf(item_index);
                if (index === -1 && index_group > -1) {
                    this.datas.agency.data.push(item_index);
                } else if (index > -1 && index_group === -1){
                    this.datas.agency.data.splice(index, 1);
                }
            }
        
        },
        /**
         * Select/Unselect agency on click
        */
        activeAgency(key){
            if(Array.isArray(this.datas.agency.data) && key !== ''){
                if(this.datas.agency.data.includes(key)){
                    let index = this.datas.agency.data.indexOf(key)
                    if(index > -1){
                        this.datas.agency.data.splice(index, 1)
                    }
                }
                else{
                    this.datas.agency.data.push(key)
                }
            }
            else{
                this.datas.agency.data = [this.datas.agency.data]
            }
            
        },
        loadDefaultAgency(){ 
            this.$store.dispatch("inter/loadDefaultAgency")
        },
        loadAccessAgency(){ 
            if(this.accessAgency.length === 0){
                this.$store.dispatch("users/accessAgency") 
            }            
        },
        loadAutocomplete(){ 
            const keyLoadAutoComplete = []
            if(this.$store.getters['autocomplete/name'].length === 0){
                keyLoadAutoComplete.push('name')
            }
            if(this.$store.getters['autocomplete/therapeutic'].length === 0){
                keyLoadAutoComplete.push('therapeutic')
            }
            if(this.$store.getters['autocomplete/measure_category'].length === 0){
                keyLoadAutoComplete.push('measure_category')
            }
            if(keyLoadAutoComplete.length > 0){
                this.$store.dispatch("autocomplete/loadAutocomplete", keyLoadAutoComplete)
            }

            if(this.autocomplete_name.length > 0 && this.loadingName)
                this.loadingName = false

            if(this.autocomplete_therapeutic.length > 0 && this.loadingTherapeutic)
                this.loadingTherapeutic = false
        }, 
        onModal(param){
            for(let i in param.value){
                if(this.datas[param.key].data.filter(x => x === param.value[i]).length === 0){                   
                    this.datas[param.key].data.push(param.value[i]);
                    if(param.key === 'therapeutic'){
                        this.datas[param.key].items.push(param.value[i]);
                        this.$refs.multiselectTherapeutic.select(param.value[i])
                    }
                }
            }            
        },
        /**
         * Parse and format data (expect agencies) before push
        */
        formDataWithOutAgency(){
            let strResultForm = "" 
            for (const [key, items] of Object.entries(this.datas)) {
                if(Array.isArray(items.data) && items.data.length > 0  && key != 'agency'){      
                    strResultForm += `${key}=${items.data.join('#')}|` 
                }else if( (key === 'date_start' || key === "date_end") && items.data ){                        
                    strResultForm += `${key}=${moment(items.data).format("MM/YYYY")}|`                        
                }else if(typeof items.data == 'string' && items.data !== ''){
                    strResultForm += `${key}=${items.data}|`
                }else if(items.options && items.value) {
                    strResultForm += `${key}=${items.value}|`
                }
            }
            return strResultForm
        },

        /**
         * Parse and format data from form before push
        */
        formData(){
            let strResultForm = "" 
            for (const [key, items] of Object.entries(this.datas)) {
                if(items.main === 'EssaisClinique') {
                    if(Array.isArray(items.data) && items.data.length > 0){
                        if(key === "measure_title" || key === 'nct_numbers' || key === 'endpoint_search'){
                            const temp = [];
                            items.data.forEach(item => {
                                temp.push(item) 
                            });
                            strResultForm += `${items.main}@${key}=${temp.join('#')}|`
                        }else{
                            strResultForm += `${items.main}@${key}=${items.data.join('#')}|`
                        }
                    }else if(typeof items.data == 'string' && items.data !== ''){
                        strResultForm += `${items.main}@${key}=${items.data}|`
                    } else if(items.options && items.value) {
                        strResultForm += `${items.main}@${key}=${items.value}|`
                    }
                }else{
                    if(Array.isArray(items.data) && items.data.length > 0 && key == 'agency'){  
                        let tmp_data = ""
                        for (let key2 in this.groupAgency) {
                            for (let item_agency in this.groupAgency[key2]) {
                                for (let item_datas in items.data) {
                                    if(item_agency == items.data[item_datas]){
                                        if(tmp_data != ''){
                                            tmp_data = tmp_data + '#'
                                        }
                                        tmp_data = tmp_data  + `${this.groupAgency[key2][item_agency].agency.join('#')}` 
                                    }
                                }
                            }
                        }
                        strResultForm += `${key}=${tmp_data}|` 
                    }
                    else if(Array.isArray(items.data) && items.data.length > 0){    
                        if(key === "therapeutic" || key === "name"){
                                const temp = [];
                                items.data.forEach(item => {
                                    temp.push(item)
                                });
                                strResultForm += `${key}=${temp.join('#')}|`
                        }else{  
                            strResultForm += `${key}=${items.data.join('#')}|` 
                        }
                    }else if( (key === 'date_start' || key === "date_end") && items.data){                        
                        strResultForm += `${key}=${moment(items.data).format("MM/YYYY")}|`                        
                    }else if(typeof items.data == 'string' && items.data !== ''){
                        strResultForm += `${key}=${items.data}|`
                    }
                }
            }
            console.log(strResultForm)
            return strResultForm
        },

        /**
         * Triggers when form is submitted
        */
        handleSubmit(search = true){ 
            
            let strResultForm = this.formData() 
            let strResultFormWithOutAgency = this.formDataWithOutAgency() 
            // alert(strResultForm)
            // alert(base64url_encode(strResultForm))
            // console.log(strResultFormWithOutAgency);
            if(strResultFormWithOutAgency === ""){
                alert(this.$t('Please fill in more values in your search.'));
                return false
            }

            let essais_clinique;
            strResultForm.indexOf("EssaisClinique@") > -1 ? essais_clinique = true : essais_clinique = false 

            let tab = "";
            if(essais_clinique){
                tab = "?tab=essais_clinique";
            }else if(this.datas.agency.data.length === 1) {
                tab = "?tab=expert"
            }else
                tab = "?tab=overview"
            const url = `/result/${base64url_encode(strResultForm)}/inter${tab}`;
            if(search){
                this.$router.push(url); // eslint-disable-line
            }else{
                return url;
            }
            
        },
        addToBoxName (datas){
            if(Array.isArray(datas) && datas.length > 0 ){  
                for (const [key, items] of Object.entries(datas)) {
                    console.log(key)
                    console.log(items)
                    if(!this.datas.name.data.includes(items)){
                        this.datas.name.data.push(items)
                    }
                }
            }
        },
        handleSelectDate() {
            if(moment(this.datas.date_start.data, 'MM/YYYY').isAfter(moment(this.datas.date_end.data, 'MM/YYYY'), 'month'))
                this.error = "End month should be after start month"
        },
        filteredName() {
            this.datas.name.items = this.autocomplete_name.filter(i => {
                return i.toLowerCase().indexOf(this.datas.name.input.toLowerCase()) !== -1 && i != "";
            });
        },
        filteredTherapeutic() {
            this.datas.therapeutic.items = this.autocomplete_therapeutic.filter(i => {
                return i.toLowerCase().indexOf(this.datas.therapeutic.input.toLowerCase()) !== -1;
            });
        },
        filteredMeasureTitle() {
            this.datas.measure_title.items = this.autocomplete_measure_category.filter(i => {
                return i.toLowerCase().indexOf(this.datas.measure_title.input.toLowerCase()) !== -1;
            });
        },
        /**
         * Validate nct number format before add
        */
        nctValidation(newTag) {
            this.datas.nct_numbers.items.push(newTag);
            setTimeout(() => {
                if(!newTag.match(this.datas.nct_numbers.validation)){
                    this.$refs.multiselectNct.deselect(newTag)
                    this.datas.nct_numbers.items.pop();
                }
            }, 100);
            
        },
        autoAddTag(query) {
            if(query.length === 11 && query.match(this.datas.nct_numbers.validation)){
                this.datas.nct_numbers.items.push(query);
                this.$refs.multiselectNct.select(query)
                setTimeout(() => {
                    document.querySelector('.nct_input input').value = ""
                }, 100);
            }
        },
        optionsDisplay(value) {
            if(value.length > 1)
                this.datas.name.showOptions = true
        },
        onCloseDrugname() {
            this.datas.name.showOptions = false
        },
        /**
         * Add element from checkedTherapeuticArea if therapeutic area is selected in multiselect
         */
        selectTherapeuticArea(therapeutic_area) {
            this.checkedTherapeuticArea.push(therapeutic_area)
        },
        /**
         * Remove element from checkedTherapeuticArea if therapeutic area is unselected in multiselect
         */
        unselectTherapeuticArea(therapeutic_area) {
            this.checkedTherapeuticArea.forEach((el, index) => {
                if(el === therapeutic_area)
                    this.checkedTherapeuticArea.splice(index,1)
            })
        },
        /**
         * Reset array checkedTherapeuticArea on clear multiselect event 
         */
        unselectAlltherapeuticArea() {
            this.checkedTherapeuticArea = []
        }

    },
    computed: {
        isAdmin,     
        accessAgency(){
            return this.$store.getters['users/access_agency']
        },
        autocomplete_name () {  
            return this.$store.getters['autocomplete/name']
        },
        autocomplete_therapeutic () {  
            return this.$store.getters['autocomplete/therapeutic']
        },
        defaultCountry() {
            return this.$store.getters['inter/agency']
        },
        groupAgency(){
            return this.$store.getters['agency/groupAgency'] 
        },
        toBoxName(){ 
            return this.$store.getters['did_you_mean/to_box_name']
        },
        autocomplete_measure_category() {  
            return this.$store.getters['autocomplete/measure_category']
        },
        regulatoryAgencies(){
            const entries = Object.entries(this.groupAgency)
            const filterEntries = entries.filter(([key, value]) => value && key === 'Regulatory')
            return Object.fromEntries(filterEntries)
        },
        CountryGroup(){
            const entries = Object.entries(this.groupAgency)
            const filterEntries = entries.filter(([key, value]) => value && key !== 'Regulatory')
            return Object.fromEntries(filterEntries)
        },
        language(){
            return this.$i18n.locale !== 'en' ? this.languages[this.$i18n.locale] : this.languages[enGB]
        }
    },
    watch:{
        toBoxName(value) {
            this.addToBoxName(value)
        },
        autocomplete_name(value) {
            if(value.length > 0)
                this.loadingName = false
        },
        autocomplete_therapeutic (value) {  
            if(value.length > 0)
                this.loadingTherapeutic = false
        },
    }
  
}
</script>

<style>
    .container-inter .box-check.form-input p {
        margin: 0 0 10px;
    }
    .container-inter .box-check input[type="checkbox"] + span {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        margin-right: 8px;  
    }
    .box_country .item-header {
        width: 100% !important;
        text-align: center !important;
        margin: 0px;
        opacity: 1;
    }
    .container-inter .box-save-search {
        margin: 15px 0;
    }
    .wrap-form-content {
        padding: 50px 15px 15px;
    }
    .multiselect.custom-tags-input > .multiselect-input{
        border: 1px solid #bbc3d0 !important;
    }
</style>