<template>
<teleport to="body">
<div class="modal fade" id="modalMemory" tabindex="-1">
  <div class="modal-dialog modal-medium modal-dialog-centered">
    <div class="modal-content model-custom main-overlay">
        <a type="button" class="close fa-pull-right" style="opacity: 1;">
            <span class="close-popup" data-bs-dismiss="modal" aria-label="Close"></span>
        </a>
        <div class="box-wrapper-modal"  v-if="!saveKeywordSuccess">
            <h2>{{$t('Rename the search')}}</h2>
            <form @submit.prevent="handleSubmitMemoryQuery"> 
            <div class="box-input mb-3">
                <input type="text" class="form-control" placeholder="Write here" v-model="name">
            </div>
            <div><button class="btn-orange" style="width: 200px;" >Enter</button></div>
            </form>
        </div>
        <div class="box-wrapper-modal" style="text-align: center; display: block;" v-if="saveKeywordSuccess">
            <div style=" padding: 9px 10px 20px 10px;font-size: 1.2em;">Your search has been saved successfully.</div>
            <button type="button" class="btn-orange" style="width: 200px;" data-bs-dismiss="modal" aria-label="Close">OK</button>
        </div>
        <div v-if="loadingSaveKeyword" class="overlay">
            <div class="loadding_circle"></div>
        </div>
    </div>
    
    </div>
</div>
</teleport>       

</template>

<script>
export default {
    name : "ModalMemory",
    props : {
        form_data : {
            type : String,
        },
        form_type : {
            type : String,
        },
        form_url : {
            type: String,
            required: false,
            default: ""
        }
    },
    data() {
        return { 
            name : "",
            query : this.form_data,
            type : this.form_type,
        }
    },
    mounted() {     
    },
    methods: {
        onEnd(){
            this.checkCanSave()
            console.log('onEnd')
        },
        handleSubmitMemoryQuery(){ 
            if(this.name.length != 0 && this.form_data){
                this.query = this.form_data
                this.type = this.form_type
                const link = this.form_url ? this.form_url : this.$route.path
                this.$store.dispatch("users/saveKeyword", { name : this.name, keyword : this.query, type : this.type, link:link} ) 
            }else if(this.form_data.length === 0){
                alert(this.$t('Please fill in more values in your search.'));
            }else{
                alert(this.$t('The field cannot be empty'));
            }
        }
    },
    computed: {
        saveKeywordSuccess() {
            return this.$store.getters["users/saveKeywordSuccess"];
        },
        loadingSaveKeyword() {
            return this.$store.getters["users/load"];
        },
    },
    watch: {          
    },
   
}
</script>

<style scoped>
.memory_search{
    padding: 30px;
    text-align: center;
}
.memory_search div{
    display: inline-block;
}
.memory_search h2{
    display: block;
    font-size: 1.3em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
.memory_search input{
    margin-bottom: 35px;
    position: relative;
    width: 268px;
    height: 31px !important;
    background: #f7f5f5;
    border:none !important;
    outline: none;
    padding: 0 10px !important;
}
.memory_search .btn-orange {
    position: relative;
    border-radius: 15px i !important;
    font-size: 11px !important;
    border: none !important;
    padding: 6px 42px !important;
    color: #fff !important;
    display: inline-block !important;
    background: linear-gradient(to bottom, rgba(255, 117, 63, 1) 0%, rgba(232, 69, 3, 1) 100%)  !important;
    width: 200px;
    text-align: center;
}
.model-custom{
    color: #232426;
    font-size: 14px;
    border-radius: 10px !important;
}

</style>